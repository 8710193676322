import React from "react"
import { Link } from "gatsby"

class NotFoundPage extends React.Component {
  render() {
    return (
      <>
        <h1>Not Found</h1>
        <p>
          This page doesn't exist. <Link to="/">Go back to the homepage.</Link>
        </p>
      </>
    )
  }
}

export default NotFoundPage
